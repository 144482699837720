import React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/layout"
import ArticleTemplateComponent from "../../components/templates/article";

const embedded = true;
const ArticleTemplate = function ({ data }) {
  return (
    <Layout embedded={embedded}>
      <ArticleTemplateComponent data={data} embedded={embedded} webViewVersion="v1" />
    </Layout>
  );
}

export default ArticleTemplate

export const query = graphql`
  query EmbeddedArticleTemplate($Title: String!) {
    strapiArticle(Title: { eq: $Title }) {
      Title
      Body
      Seotitle
      Seodescription
      Author {
        username
      }
    }
  }
`